import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Section, { BackgroundColor, TextAlign } from "components/section";
import Map from "content/shared/map";
import image1 from "images/etcheverry-mindurry.jpg";
export const _frontmatter = {
  "meta": {
    "title": "Etcheverry Mindurry | La solution pour la collecte, le tri et la valorisation de vos déchets",
    "description": "Vous avez fait le point sur vos besoins ? Vous avez des questions ?"
  }
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ImageBlock = makeShortcode("ImageBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Section background={BackgroundColor.DARK} mdxType="Section">
      <h2>{`Contactez-nous`}</h2>
      <p>{`Vous avez fait le point sur vos besoins`}{` `}{`? Vous avez des questions`}{` `}{`? `}</p>
      <br />Si cela est le cas, contactez-nous et échangeons.
      <p>{`Par téléphone au `}<strong parentName="p"><a parentName="strong" {...{
            "href": "tel://0559412000"
          }}>{`05 59 41 20 00`}</a></strong></p>
      <p>{`Par e-mail à l'adresse : `}<strong parentName="p"><a parentName="strong" {...{
            "href": "mailto://etcheverry-mindurry@wanadoo.fr"
          }}>{`contact@etcheverry-mindurry.fr`}</a></strong></p>
      <h2>{`Où sommes-nous situé`}{` `}{`?`}</h2>
      <p>{`Notre bureau et nos installations sont situés à l'adresse suivante:`}</p>
      <p>{`Route départementale 254`}<br />{`64200 Bassussarry`}</p>
      <p>{`Voir sur `}<a href="https://goo.gl/maps/mmeekvpzATper7jN7" target="_blank">{`Google Maps`}</a>{` ou `}<a href="https://www.waze.com/en/live-map/directions/fr/nouvelle-aquitaine/bassussarry/entr-generale-etcheverry-et-mindurry?place=ChIJ85CDm-wTUQ0RxtpzIhaidAs">{`Waze`}</a></p>
    </Section>
    <ImageBlock content={image1} width={100} mdxType="ImageBlock" />
    <Map mdxType="Map" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      